import React from "react";

import Products from "../products";
import ProductCard from "../../components/ProductCards/ProductCards";
import SEO from "../../components/SEO/Seo";

import { aceites } from "../../data/acietes";

const EnvasesAlimentos = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA - Aceites."
        keywords="Blue Sky SA ,Envases de vidrio "
        description="Blue Sky SAEnvases de vidrio "
      />
      <Products subtitle="Aceites">
        <ProductCard data={aceites} />
      </Products>
    </>
  );
};

export default EnvasesAlimentos;
