import {
    Aceite1,
    Aceite2,
    Aceite3,
    Aceite4,
    Aceite5,
    Aceite6,
    Aceite7,
    Aceite8,
    Aceite9,
    Aceite10,
    Aceite11,
    Aceite12,
    Aceite13,
    Aceite14,
    Aceite15,
    Aceite16,
    Aceite17,
    Aceite18,
    Aceite19,
    Aceite20,
    Aceite21,
    Aceite22,
    Aceite23,
    Aceite24,
    Aceite26,
    Aceite27,
    Aceite28,
    Aceite29,
    Aceite30,
    Aceite31,
    Aceite32,
    AceiteModal1,
    AceiteModal2,
    AceiteModal3,
    AceiteModal4,
    AceiteModal5,
    AceiteModal6,
    AceiteModal7,
    AceiteModal8,
    AceiteModal9,
    AceiteModal10,
    AceiteModal11,
    AceiteModal12,
    AceiteModal13,
    AceiteModal14,
    AceiteModal15,
    AceiteModal16,
    AceiteModal17,
    AceiteModal18,
    AceiteModal19,
    AceiteModal20,
    AceiteModal21,
    AceiteModal22,
    AceiteModal23,
    AceiteModal24,
    AceiteModal26,
    AceiteModal27,
    AceiteModal28,
    AceiteModal29,
    AceiteModal30,
    AceiteModal31,
    AceiteModal32,
    AceiteItalia500,
    AceiteItalia500Modal
} from "../images/productos/aceites"

import {
    PDF1,
    PDF2,
    PDF3,
    PDF4,
    PDF5,
    PDF6,
    PDF7,
    PDF8,
    PDF9,
    PDF10,
    PDF11,
    PDF12,
    PDF13,
    PDF14,
    PDF15,
    PDF16,
    PDF17,
    PDF18,
    PDF19,
    PDF20,
    PDF21,
    PDF22,
    PDF23,
    PDF24,
    PDF26,
    PDF27,
    PDF28,
    PDF29,
    PDF30,
    PDF31,
    PDF32,
    PDFAceiteItalia500
} from "../../static/14-aceites/index"

export const aceites = [

    {
        srcModal: AceiteModal6,
        size: 375,
        src: Aceite6,
        pdfDownload: PDF6,
    },
    {
        srcModal: AceiteModal8,
        size: 375,
        src: Aceite8,
        pdfDownload: PDF8,
    }, {
        srcModal: AceiteModal9,
        size: 375,
        src: Aceite9,
        pdfDownload: PDF9,
    },
    {
        srcModal: AceiteModal10,
        size: 375,
        src: Aceite10,
        pdfDownload: PDF10,
    },
    {
        srcModal: AceiteModal11,
        size: 375,
        src: Aceite11,
        pdfDownload: PDF11,
    },

    {
        srcModal: AceiteModal19,
        size: 375,
        src: Aceite19,
        pdfDownload: PDF19,
    },
    {
        srcModal: AceiteModal20,
        size: 375,
        src: Aceite20,
        pdfDownload: PDF20,
    },
    {
        srcModal: AceiteItalia500Modal,
        size: 375,
        src: AceiteItalia500,
        pdfDownload: PDFAceiteItalia500,
    },
    {
        srcModal: AceiteModal22,
        size: 375,
        src: Aceite22,
        pdfDownload: PDF22,
    },
    {
        srcModal: AceiteModal23,
        size: 375,
        src: Aceite23,
        pdfDownload: PDF23,
    },


    {
        srcModal: AceiteModal26,
        size: 375,
        src: Aceite26,
        pdfDownload: PDF26,
    },
    {
        srcModal: AceiteModal27,
        size: 375,
        src: Aceite27,
        pdfDownload: PDF27,
    },
    {
        srcModal: AceiteModal28,
        size: 375,
        src: Aceite28,
        pdfDownload: PDF28,
    },
    {
        srcModal: AceiteModal29,
        size: 375,
        src: Aceite29,
        pdfDownload: PDF29,
    },
    {
        srcModal: AceiteModal30,
        size: 375,
        src: Aceite30,
        pdfDownload: PDF30,
    },
    {
        srcModal: AceiteModal32,
        size: 375,
        src: Aceite32,
        pdfDownload: PDF32,
    },
];